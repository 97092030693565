import { SanityDividerPhoto } from '@data/sanity/queries/types/modules'

import Photo from '@components/photo'
import { backgroundColorClasses } from '@modules/grid'

type DividerPhotoProps = SanityDividerPhoto

const DividerPhoto = ({ photo, background }: DividerPhotoProps) => (
  <section
    className={`${background ? backgroundColorClasses[background] : ''}`}
  >
    <div className="container">
      <Photo image={photo} layout="responsive" />
    </div>
  </section>
)

export default DividerPhoto
